<template>
  <base-layout-two
    page-title="Edit Room Type"
    page-default-back-link="/room_types"
  >
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Room Type Details">
          <ion-grid>
            <ion-row>
              <!-- Property -->
              <!-- using v-if length > 0 as a temporary bug fix for ion-select not showing up from http requests -->
              <ion-col v-if="properties.length > 0">
                <base-input label-text="Property *">
                  <v-field
                    name="property"
                    v-slot="{ field }"
                    v-model="room_type.property_id"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option
                        v-for="property in properties"
                        :key="property.id"
                        :value="property.id"
                      >
                        {{ property.name }}
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message
                  name="property"
                  class="error-message"
                ></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Room Type Name -->
              <ion-col size="12" size-lg="12">
                <base-input label-text="Room Type Name *">
                  <v-field
                    name="room_type"
                    v-slot="{ field }"
                    v-model="room_type.name"
                    :rules="required"
                  >
                    <ion-input
                      name="room_type"
                      v-bind="field"
                      placeholder="Room Type"
                      type="text"
                      autocapitalize="words"
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message
                  name="room_type"
                  class="error-message"
                ></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Price Retail -->
              <ion-col size="12" size-lg="12">
                <base-input label-text="Retail Price *">
                  <v-field
                    name="price_retail"
                    v-slot="{ field }"
                    v-model="room_type.price_retail"
                    :rules="required"
                  >
                    <ion-input
                      name="price_retail"
                      v-bind="field"
                      placeholder="0.00"
                      type="number"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message
                  name="price_retail"
                  class="error-message"
                ></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <base-input label-text="Description">
                  <v-field
                    name="description"
                    v-slot="{ field }"
                    v-model="room_type.description"
                  >
                    <ion-textarea
                      name="description"
                      :auto-grow="true"
                      rows="1"
                      v-bind="field"
                      placeholder="Description of room"
                      autocapitalize
                      autocorrect
                    ></ion-textarea>
                  </v-field>
                </base-input>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,

    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      room_type: {},
      properties: [],
      required: yup.string().required(),
    };
  },

  async ionViewWillEnter() {
    await this.fetchRoomType();
    await this.fetchProperties();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchRoomType() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/room_types/${this.$route.params.id}`)
        .then((response) => {
          this.room_type = response.data.room_type;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchProperties() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties`)
        .then((response) => {
          this.properties = response.data.properties;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/room_types/${this.$route.params.id}`,
        data: this.room_type,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.room_type = response.data.room_type;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Room Type Updated", color: "secondary" });

          this.$router.replace(`/room_types/${this.room_type.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>